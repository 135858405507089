import * as React from 'react';
import AnimationVideo from "../assets/AnimationVideo.mp4";
import {video} from './svg-animation-video.module.scss';

export default function SVGAnimationVideo() {
    return (
        <video controls className={video} preload="metadata">
            <source src={`${AnimationVideo}#t=0.001`} type="video/mp4" />
        </video>
    )
}
