import * as React from 'react';
import ScreenshotVideo from '../assets/CPUVsNetworkVisual01.mp4';
import {video} from './svg-animation-video.module.scss';

export default function CPUVsNetworkVisualVideo() {
    return (
        <video controls className={video} preload="metadata">
            <source src={`${ScreenshotVideo}#t=0.001`} type="video/mp4" />
        </video>
    )
}
